/*=========================================================================================
  File Name: moduleBrokerPackageActions.js
  Description: BrokerPackage Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  // addItem({ commit }, item) {
  //   debugger;
  //   var Token={Authorization: 'Bearer ' + localStorage.accessToken};
  //   return new Promise((resolve, reject) => {
  //     axios.post("API/Package/AddNewCountry", item,{headers:Token})
  //       .then((response) => {
  //         commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.Id }))
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },

  fetchDataListItems({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/ServiceProviderSubscription/GetAll",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_ServiceProviderSubscriptions', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  GetAllPackiges({ commit }) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/Package/GetAll",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_Packages', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  SearchBrokerPackageData({ commit }, search) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      
      axios
        .post("API/BrokerSubscription/SearchBrokerPackageData", search,{headers:Token})
        .then(response => {
          resolve(response);
          commit("SET_BrokerPackages", response.data.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // updateItem({ commit }, item) {
  //   var Token={Authorization: 'Bearer ' + localStorage.accessToken};
  //   return new Promise((resolve, reject) => {
  //     axios.put("API/Country/UpdateCountry", item,{headers:Token})
  //       .then((response) => {
  //         commit('UPDATE_Country', item)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },

  // GetItemByID({ commit }, itemid) {
  //   return new Promise((resolve, reject) => {
  //     axios.get("API/Country/GetCountryByID?Id=" + itemid)
  //       .then((response) => {
  //         commit('UPDATE_Country', response.data.Data)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },

  // removeItem({ commit }, item) {
  //   debugger;
  //   var Token={Authorization: 'Bearer ' + localStorage.accessToken};
  //   return new Promise((resolve, reject) => {
  //     axios.delete("API/Country/DeleteCountry?countryId="+item.Id,{headers:Token})
  //       .then((response) => {
  //         commit('REMOVE_ITEM', item.Id)
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
}
