/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    state.countries.unshift(item)
  },
  SET_ServiceProviderSubscriptions(state, ServiceProviderSubscriptions) {
    debugger;
    try {
      //const parsedData = JSON.parse(countries);
      state.ServiceProviderSubscriptions = ServiceProviderSubscriptions;
      //state.OutbreakCountries =  countries;
    } catch (error) {
      console.error("Failed to parse ServiceProviderSubscription data JSON:", error);
      state.ServiceProviderSubscriptions = [];
    }
  },
  SET_Packages(state, Packages) {
    debugger;
    try {
      //const parsedData = JSON.parse(countries);
      state.Packages = Packages;
      //state.OutbreakCountries =  countries;
    } catch (error) {
      console.error("Failed to parse Packages data JSON:", error);
      state.Packages = [];
    }
  },
  UPDATE_Country(state, country) {
    debugger
    const countryIndex = state.countries.findIndex((p) => p.Id == country.Id)
    Object.assign(state.countries[countryIndex], country)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.countries.findIndex((p) => p.Id == itemId)
      state.countries.splice(ItemIndex, 1)
  },
}
