<template style="font-family:Almarai">
  <div>
    <!-- <vx-card ref="filterCard" :title="$t('Filters')" class="user-list-filters mb-8 p-4" collapse-action>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label style="font-weight: bold;">{{ $t("BrokerName") }}</label>
          <vs-input v-model="search.Name" class="w-full" style="margin-top: 10px;" />
        </div>
        <div style="width: 200px; margin:4px;">
          <label style="font-weight: bold;">{{ $t("BrokerPackageType") }}</label>
          <v-select label="name" style="margin-top: 10px;" name="Name" v-model="search.BrokerPackageType" :options="BrokerPackageTypeOptions" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <vs-button class="my-6 rounded-sm" color="warning" @click="handleSearch">{{ $t("Search") }}</vs-button>
        </div>
      </div>
    </vx-card> -->

    <div id="data-list-list-view" class="data-list-container">
      <vs-table ref="table" id="Scroll" pagination :max-items="itemsPerPage" search :data="ServiceProviderSubscriptiondata">
        <template slot="thead">
          <vs-th style="font-size: 16px; font-family: Almarai; color: green;" sort-key="Name">{{ $t("ServiceProviderName") }}</vs-th>
          <vs-th style="font-size: 16px; font-family: Almarai; color: green;" sort-key="Address">{{ $t("SubName") }}</vs-th>
          <vs-th style="font-size: 16px; font-family: Almarai; color: green;" sort-key="Email">{{ $t("Price") }}</vs-th>
          <vs-th style="font-size: 16px; font-family: Almarai; color: green;" sort-key="PhoneNumber">{{ $t("NumperOfMounths") }}</vs-th>
        </template>
        <template slot-scope="{ data }">
          <tbody>
            <tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <p style="font-size: 16px; font-family: Almarai;">{{ tr.ServicesProvider.NameAR }}</p>
              </vs-td>
              <vs-td>
                <p style="font-size: 16px; font-family: Almarai;">{{ tr.subscriptionPeriod.Name }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.subscriptionPeriod.Price }}</p>
              </vs-td>
              <vs-td>
                <p class="product-name font-medium truncate">{{ tr.subscriptionPeriod.NomperOfMonths }}</p>
              </vs-td>
            </tr>
          </tbody>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
//import { domain } from "@/gloabelConstant.js";
import moduleServiceProviderSubscription from "@/store/ServiceProviderSubscription/moduleServiceProviderSubscription.js";

export default {
  data() {
    return {
      itemsPerPage: 10,
      rowDataForDelete: {},
      search: {
        Name: "",
        BrokerPackageType: ""
      },
      //BrokerPackageTypeOptions: [],
      isMounted: false
    };
  },
  computed: {
    ServiceProviderSubscriptiondata() {
      return this.$store.state.ServiceProviderSubscriptionList.ServiceProviderSubscriptions;
    }
  },
  methods: {
    // GetAllpackiges() {
    //   var x = this.$store.state.BrokerPackageList.Packages;
    //   this.BrokerPackageTypeOptions = x.map(item => item.Name);
    // },
    // handleSearch() {
    //   this.$store
    //     .dispatch("BrokerPackageList/SearchBrokerPackageData", this.search)
    //     .then(res => {
    //       this.BrokerPackagedata = res.data.data;
    //     });
    // },
  //   openConfirm(data) {
  //     this.rowDataForDelete = data;
  //     this.$vs.dialog({
  //       type: "confirm",
  //       color: "danger",
  //       title: "Confirm",
  //       text: "Are you sure you want to delete this record?",
  //       accept: this.acceptAlert
  //     });
  //   },
  //   acceptAlert() {
  //     this.deleteData(this.rowDataForDelete);
  //     this.$vs.notify({
  //       time: 8000,
  //       color: "success",
  //       title: "Deleted record",
  //       text: "Successfully Deleted"
  //     });
  //   }
   },
  created() {
    if (!moduleServiceProviderSubscription.isRegistered) {
      this.$store.registerModule("ServiceProviderSubscriptionList", moduleServiceProviderSubscription);
      moduleServiceProviderSubscription.isRegistered = true;
    }
    // Fetch data from store
    this.$store.dispatch("ServiceProviderSubscriptionList/fetchDataListItems");
    //this.$store.dispatch("BrokerPackageList/GetAllPackiges").then(() => {
      //this.GetAllpackiges();
   // });
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #28C76F;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #FF6347;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}

#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }
    .custom-label {
      font-size: 10px;
      margin-bottom: 10px;
    }
    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
